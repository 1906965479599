<template>
  <!-- 下达任务/申请任务 by lbj-->
  <div class="addFrom">
    <van-nav-bar
      :title="title"
      left-text
      left-arrow
      @click-left="onClickLeft"
    ></van-nav-bar>

    <!-- 基本信息 -->
    <van-form
      ref="form"
      :inline="true"
      :model="rpParamInfo"
      label-position="right"
    >
      <div class="right_content">
        <div class="sheet">
          <div
            v-for="(item, index) in rpParamInfo"
            :key="index"
            class="right_content"
          >
            <div
              v-for="(list, idx) in item"
              :key="idx"
              class="elwrapper"
              v-show="inputControltype2.indexOf(list.controltype) > -1"
            >
              <van-field
                v-if="inputControltype2.indexOf(list.controltype) > -1"
                v-model="list.val"
                :name="list.chnname"
                :label="list.chnname"
                :ref="list.fieldname"
                :required="list.mustinput"
                :disabled="list.isreadonly"
                :rules="[{ required: list.mustinput, message: '必填' }]"
                rows="2"
                :type="
                  list.controltype === 'memo'
                    ? 'textarea'
                    : list.listsql === 'float'
                    ? 'number'
                    : ''
                "
                :autosize="list.controltype === 'memo'"
                :readonly="
                  (list.controltype === 'combobox' &&
                    list.comdropstyle != 'lsEditList') ||
                    list.controltype === 'radiogroup' ||
                    list.controltype === 'checkgroup'
                "
                :clickable="
                  (list.controltype === 'combobox' &&
                    list.comdropstyle != 'lsEditList') ||
                    list.controltype === 'radiogroup' ||
                    list.controltype === 'checkgroup'
                "
                :placeholder="
                  (inputControltype.indexOf(list.controltype) > -1
                    ? '请输入'
                    : '请选择') + list.chnname
                "
                @click="
                  ;((list.controltype === 'combobox' ||
                    list.controltype === 'lookupcombobox' ||
                    list.controltype === 'radiogroup' ||
                    list.controltype === 'checkgroup') &&
                    list.comdropstyle != 'lsEditList' &&
                    !list.ispoptree &&
                    comboboxNoispoptreefocus(
                      list.listsql,
                      list.controltype,
                      list
                    )) ||
                    ((list.controltype === 'datetime' ||
                      list.controltype === 'maskedit' ||
                      list.controltype === 'longdatetime') &&
                      showdatepick(list)) ||
                    (list.controltype === 'combobox' &&
                      list.comdropstyle != 'lsEditList' &&
                      list.ispoptree &&
                      showTree(list))
                "
              >
                <template
                  v-if="
                    (list.controltype === 'combobox' ||
                      list.controltype === 'lookupcombobox' ||
                      list.controltype === 'radiogroup' ||
                      list.controltype === 'checkgroup') &&
                      !list.ispoptree
                  "
                  #right-icon
                >
                  <van-icon
                    name="arrow-down"
                    @click="
                      !(list.chnname === '当前用户') &&
                        comboboxNoispoptreefocus(
                          list.listsql,
                          list.controltype,
                          list
                        )
                    "
                  />
                </template>
                <template
                  v-else-if="list.controltype === 'combobox' && list.ispoptree"
                  #right-icon
                >
                  <van-icon
                    name="arrow-down"
                    @click="!(list.chnname === '当前用户') && showTree(list)"
                  />
                  <van-icon
                    name="cross"
                    v-if="
                      (list.chnname === '验收人2' && yznum == 2) ||
                        list.chnname === '验收人3'
                    "
                    @click="jys()"
                  />
                </template>
              </van-field>
              <van-field
                v-else-if="list.controltype === 'checkbox'"
                :disabled="list.chnname === '当前用户'"
                :name="list.chnname"
                :label="list.chnname"
                :ref="list.fieldname"
                :required="list.mustinput"
                :rules="[{ required: list.mustinput, message: '必填' }]"
              >
                <template #input>
                  <van-switch v-model="list.val" size="20" />
                </template>
              </van-field>
            </div>
          </div>
        </div>
      </div>
    </van-form>
    <van-popup
      v-model="showPicker"
      :close-on-click-overlay="false"
      :close-on-popstate="false"
      round
      position="bottom"
    >
      <van-picker
        :title="Selectformdata.chnname"
        show-toolbar
        :columns="choices"
        @confirm="onConfirm"
        @cancel="onCancel"
      />
    </van-popup>
    <van-popup v-model="showDtPicker" round position="bottom">
      <van-datetime-picker
        v-model="currentDate"
        :type="
          Selectformdata.controltype === 'datetime'
            ? 'date'
            : Selectformdata.controltype === 'longdatetime'
            ? 'datetime'
            : Selectformdata.controltype === 'maskedit'
            ? 'time'
            : 'date'
        "
        :min-date="minDate"
        :max-date="maxDate"
        :title="Selectformdata.chnname"
        @confirm="onDtConfirm"
        @cancel="showDtPicker = false"
      />
    </van-popup>
    <!-- 树弹窗 -->
    <van-popup
      class="tree_popup"
      v-model="showTreePop"
      closeable
      close-icon="arrow-left"
      close-icon-position="top-left"
      position="right"
      :style="{ width: '100%', height: '100%' }"
    >
      <van-sticky class="fixed_div">
        <div class="tree_title">
          <span>{{ `请选择${Selectformdata.chnname}` }}</span>
        </div>
        <div class="tree_search">
          <van-search
            v-model="searchTreeVal"
            placeholder="请输入搜索关键词"
            @search="onSearchTree"
          />
        </div>
        <div class="tree_head">
          <span class="head_start" @click="backTo(true)">开始</span>
          <span
            v-for="(ele, idex) in clickParentTree"
            :key="idex"
            :class="idex == clickParentTree.length - 1 ? 'theLast' : 'noLast'"
            @click="backTo(ele)"
          >
            <span class="division">></span>
            {{ ele.keyname }}
          </span>
        </div>
        <van-divider
          :style="{
            color: '#E8E8E8',
            borderColor: '#E8E8E8',
            margin: '5px 0 0 0'
          }"
        />
      </van-sticky>
      <div class="tree_body">
        <!-- 岗位树和员工树 -->
        <div v-if="whichTree == 'station' || whichTree == 'employee'">
          <!-- 多选树 -->
          <div v-if="multiseltree">
            <!-- 父节点 -->
            <van-cell
              class="parents_cell"
              v-for="(item, index) in parentTreeList"
              :key="index + item.keyname"
              :title="item.keyname"
            >
              <template #default>
                <div
                  :class="item.hasLower ? 'haveClo' : 'noClo'"
                  @click="checkParent(item)"
                >
                  <van-icon name="arrow-down" />
                  <span>下级</span>
                </div>
              </template>
              <template #icon>
                <van-checkbox
                  v-model="item.checked"
                  @click="tickParent(item)"
                  shape="square"
                ></van-checkbox>
              </template>
            </van-cell>
            <!-- 子节点 -->
            <van-cell
              class="childs_cell"
              v-for="(it, idx) in childsTreeList"
              :key="idx"
            >
              <template #title>
                <van-checkbox v-model="it.checked" shape="square">
                  <van-button
                    size="small"
                    :icon="whichTree == 'employee' ? 'user-o' : 'manager-o'"
                    :type="whichTree == 'employee' ? 'warning' : 'primary'"
                  ></van-button>
                  <span class="check_name">{{ it.keyname }}</span>
                </van-checkbox>
              </template>
            </van-cell>
          </div>
          <!-- 非多选 -->
          <div v-else>
            <!-- 父节点 -->
            <van-cell
              class="parents_cell"
              v-for="(item, index) in parentTreeList"
              :key="index + item.keyname"
              :title="item.keyname"
              @click="checkParent(item)"
            >
              <template #default>
                <div :class="item.hasLower ? 'haveClo' : 'noClo'">
                  <van-icon name="arrow-down" />
                  <span>下级</span>
                </div>
              </template>
            </van-cell>
            <!-- 子节点 -->
            <van-cell
              class="childs_cell"
              v-for="(it, idx) in childsTreeList"
              :key="idx"
              @click="checkChild(it)"
            >
              <template #title>
                <van-button
                  size="small"
                  :icon="whichTree == 'employee' ? 'user-o' : 'manager-o'"
                  :type="whichTree == 'employee' ? 'warning' : 'primary'"
                ></van-button>
                <span class="check_name">{{ it.keyname }}</span>
              </template>
            </van-cell>
          </div>
        </div>
        <!-- 其他树 -->
        <div v-else>
          <!-- 多选树 -->
          <div v-if="multiseltree">
            <van-cell
              class="parents_cell other_cell"
              center
              v-for="(item, index) in otherTreeList"
              :key="index + item.keyname"
            >
              <template #icon>
                <van-checkbox
                  v-model="item.checked"
                  @click="tickParent(item)"
                  shape="square"
                ></van-checkbox>
              </template>
              <template #title>
                <van-button
                  size="small"
                  icon="cluster-o"
                  color="#1491f7"
                ></van-button>
                <span class="check_name">{{ item.keyname }}</span>
              </template>
              <template #default>
                <div
                  v-if="item.hasLower"
                  class="haveClo"
                  @click.stop="hasLower(item)"
                >
                  <van-icon name="arrow-down" />
                  <span>下级</span>
                </div>
              </template>
            </van-cell>
          </div>
          <!-- 非多选树 -->
          <div v-else>
            <van-cell
              class="parents_cell other_cell"
              center
              v-for="(item, index) in otherTreeList"
              :key="index + item.keyname"
              @click="checkChild(item)"
            >
              <template #title>
                <van-button
                  size="small"
                  icon="cluster-o"
                  color="#1491f7"
                ></van-button>
                <span class="check_name">{{ item.keyname }}</span>
              </template>
              <template #default>
                <div
                  v-if="item.hasLower"
                  class="haveClo"
                  @click.stop="hasLower(item)"
                >
                  <van-icon name="arrow-down" />
                  <span>下级</span>
                </div>
              </template>
            </van-cell>
          </div>
        </div>
      </div>
      <div class="tree_foot" v-if="multiseltree">
        <van-button type="primary" size="small" @click="cleanChecked"
          >清除</van-button
        >
        <van-button
          type="info"
          size="small"
          :disabled="!canComfirm"
          @click="comfirmChecked"
          >确定</van-button
        >
      </div>
    </van-popup>
    <!-- 不同意面试弹窗 -->
    <van-dialog
      v-model="showBTYDia"
      :title="msg"
      show-cancel-button
      :confirm-button-text="$t('module.confirm')"
      :cancel-button-text="$t('module.cancel')"
      :before-close="closeBTYDia"
    />
    <van-dialog
      v-model="showDia"
      :title="'作废原因'"
      show-cancel-button
      :before-close="closeDia"
    >
      <div style="padding: 10px">
        <van-cell class="desc_cell" :value="'作废原因'" />
        <van-field
          class="desc_field"
          v-model="checkmemo"
          rows="3"
          autosize
          type="textarea"
          :placeholder="'作废原因'"
        />
      </div>
    </van-dialog>
    <van-button
      v-if="yznum < 3"
      class="foot"
      plain
      type="info"
      @click="addys"
      size="small"
      >+增加验收人</van-button
    >
    <div class="footer_btn">
      <van-button class="backButton" type="info" @click="onClickLeft"
        >返回</van-button
      >
      <van-button type="info" :disabled="!hasroot" @click="ok">提交</van-button>
      <van-button
        v-if="!autoid == ''"
        type="danger"
        :disabled="!hasroot"
        @click="zf"
        >作废</van-button
      >
    </div>
  </div>
</template>
<script>
import { Popup, DatetimePicker, Picker, Notify, Dialog, Toast } from 'vant'
import { parseTime, createGguid } from '@/utils'
import { add } from '@/utils//cal'
import { GetFilterDropDownList, getSqlValue } from '@api/wx.js'
import {
  getCommTreeData,
  beforeAddGsTaskListInfo,
  addGsTaskList,
  checkHasGsTaskPostPri,
  getGsTaskNewDefaultValue,
  saveGsTaskList,
  getGsTaskRecordInfo,
  rePostGsTask,
  cancelNotCheckGsTask,
  rePostGsTaskForEmp,
  cancelNotSureGsTask,
  getKhTimeList
} from '@api/wxjx.js'
const userInfo = localStorage.userInfo ? JSON.parse(localStorage.userInfo) : {}
export default {
  name: 'wxForm',
  components: {
    'van-datetime-picker': DatetimePicker,
    'van-picker': Picker,
    'van-popup': Popup
  },
  data() {
    return {
      userInfo,
      moduleno: '201',
      autoid: this.$route.query.autoid || '',
      from: this.$route.query.from || '/home',
      type: this.$route.query.type, //1:上级下达任务，2：下属申请任务
      title: this.$route.query.type == '1' ? '下达任务' : '申请任务',
      rpParam: [],
      rpParamInfo: [],
      guid: null, // 全球唯一标识码
      Selectformdata: {}, // 选中的字段（用于接收弹窗返回信息）
      empcodeData: [], //工号字段输入姓名自动转化后，多笔数据
      keycode: 0, // 键盘key
      intarry: ['int', 'smallint'], // sql整数类型
      floatarry: ['money', 'float', 'numeric'], // sql小数类型
      datetime: ['datetime', 'smalldatetime'], // sql时间类型
      empcodeData: [], //工号字段输入姓名自动转化后，多笔数据
      active: '', // 当前要打开的弹窗 组件
      showPicker: false,
      showDtPicker: false,
      currentDate: new Date(),
      minDate: this.dd('min'),
      maxDate: this.dd('max'),
      showTreePop: false, // 树弹窗显示
      whichTree: '', // 树类型
      searchTreeVal: '', // 树弹窗搜索词
      allTreeList: [], // 所有树集合
      parentTreeList: [], // 上级树集合（针对岗位树和员工数）
      childsTreeList: [], // 下级树集合（针对岗位树和员工数）
      choices: [], // 下拉框选项
      clickParentTree: [], // 储存点击过的父节点
      otherTreeList: [], // 其他类型树集合
      multiseltree: false, // 是否是多选树
      canComfirm: false, // 多选树确定按钮是否可以点击
      inputControltype: ['edit', 'memo'],
      inputControltype2: [
        'edit',
        'memo',
        'combobox',
        'radiogroup',
        'checkgroup',
        'lookupcombobox',
        'datetime',
        'longdatetime',
        'maskedit'
      ],
      hasroot: false,
      yznum: 1,
      showBTYDia: false,
      msg: '',
      paramvaluelist: {},
      showDia: false,
      checkmemo: '',
      khTimeList: []
    }
  },
  created() {
    this.checkHasGsTaskPostPri()
    this._getKhTimeList()
    // this.ini()
  },
  watch: {
    rpParam: {
      async handler(n) {
        if (n) {
          const list = n
          let data = []
          const groups = []
          const idxs = [0]
          for (let idx = 0; idx < list.length; idx++) {
            let item = list[idx]
            let defvalue = ''
            if (item.isdefsql) {
              defvalue = await this.SqlValue(item.cdefaultvalue)
            } else if (item.cdefaultvalue) {
              defvalue = item.cdefaultvalue
            }
            item.pname = item.pname.trim()
            if (
              item.pname === 'crman' ||
              item.pname === 'username' ||
              item.pname === '用户名' ||
              item.pname === '当前用户'
            ) {
              defvalue = this.userInfo.username
            }
            if (
              item.chnname === 'crman' ||
              item.chnname === 'username' ||
              item.pname === '用户名' ||
              item.pname === '当前用户'
            ) {
              item.chnname = '当前用户'
            }
            item.val = item.listsql == 'float' ? null : defvalue
            item.val1 = item.listsql == 'float' ? null : defvalue
            item.check = true // 复选框
            item.isrange = false // 复选框
            item.fieldname = item.pname
            if (item.breakline && idx !== 0) idxs.push(idx)
          }
          if (idxs.length > 1) {
            idxs.forEach((it, i) => {
              if (i > 0) groups.push(list.slice(idxs[i - 1], idxs[i]))
            })
            groups.push(list.slice(idxs[idxs.length - 1]))
            this.rpParamInfo = JSON.parse(JSON.stringify(groups))
            this.rpParamInfo.forEach(item => {
              if (item[0].controltype == 'datetime') {
                if (item[0].val == '') {
                  item[0].val = parseTime(new Date(), '{y}-{m}-{d}')
                }
                if (item[0].isrange) {
                  if (item[0].val1 == '') {
                    item[0].val1 = parseTime(new Date(), '{y}-{m}-{d}')
                  }
                }
              }
            })
          } else {
            this.rpParamInfo = [list]
          }
          console.log(this.rpParamInfo)
        }
      },
      immediate: true
    },
    showTreePop(n) {
      if (n == false) {
        this.parentTreeList = [] // 上级树集合
        this.childsTreeList = [] // 子节点
        this.clickParentTree = [] // 点击过的父节点
        this.otherTreeList = [] // 其他树节点
        this.searchTreeVal = ''
      }
    },
    allTreeList: {
      handler(n) {
        let array = n.filter(ele => {
          return ele.checked == true
        })
        if (array.length > 0) {
          this.canComfirm = false
          if (this.whichTree == 'station') {
            for (let i = 0; i < array.length; i++) {
              const it = array[i]
              if (it.issta == true) {
                this.canComfirm = true
                return
              }
            }
          } else if (this.whichTree == 'employee') {
            for (let l = 0; l < array.length; l++) {
              const item = array[l]
              if (item.isdept == 0 && item.levelid == 16) {
                this.canComfirm = true
                return
              }
            }
          } else {
            // 其他树
            this.canComfirm = true
          }
        } else {
          this.canComfirm = false
        }
      },
      deep: true
    }
  },
  methods: {
    // 初始化字段
    ini() {
      const o = {
        comdropstyle: 'lsFixdList',
        pname: 'autoid',
        controltype: 'edit',
        autoid: 4626,
        mustinput: true,
        listsql: null,
        labelwidth: 90,
        multiseltree: false,
        popqueryproc: null,
        popupdatefield: '',
        popbackkeyid: '',
        chnname: '单据ID',
        cdefaultvalue: null,
        ispoptree: false,
        controlwidth: 200,
        breakline: false,
        isreadonly: false,
        isshow: true,
        poptreename: null,
        isdefsql: false,
        val: ''
      }
      this.rpParam = []
      if (true) {
        // 申请日期
        let d23 = JSON.parse(JSON.stringify(o))
        d23.pname = 'khtimename'
        d23.chnname = '考核周期'
        d23.controltype = 'combobox'
        d23.listsql = 'khTimeList'
        d23.isreadonly = !(this.type == '1')
        this.rpParam.push(d23)
        // 申请日期
        let d18 = JSON.parse(JSON.stringify(o))
        d18.pname = 'reqdate'
        d18.chnname = '申请日期'
        d18.isreadonly = true
        this.rpParam.push(d18)
        // 任务名称
        let d1 = JSON.parse(JSON.stringify(o))
        d1.pname = 'taskname'
        d1.chnname = '任务名称'
        this.rpParam.push(d1)
        // 评分方式
        let d22 = JSON.parse(JSON.stringify(o))
        d22.pname = 'pftype'
        d22.chnname = '评分方式'
        d22.controltype = 'combobox'
        d22.listsql = '@评分方式'
        this.rpParam.push(d22)
        // 任务名称
        let d2 = JSON.parse(JSON.stringify(o))
        d2.pname = 'tasksource'
        d2.chnname = '任务来源'
        d2.isreadonly = true
        d2.cdefaultvalue = this.type == '1' ? '上级通知' : '员工提交'
        this.rpParam.push(d2)
        // 责任人
        let d3 = JSON.parse(JSON.stringify(o))
        d3.pname = 'empcode'
        d3.chnname = '责任人'
        d3.controltype = this.type == '1' ? 'combobox' : ''
        d3.mustinput = this.type == '1' ? true : false
        d3.ispoptree = true
        d3.poptreename = 'emptree'
        d3.popupdatefield = 'empid'
        d3.popbackkeyid = 'empid'
        d3.isreadonly = !(this.type == '1')
        this.rpParam.push(d3)
        // 责任人empid
        let d4 = JSON.parse(JSON.stringify(o))
        d4.pname = 'empid'
        d4.chnname = '责任人empid'
        d4.controltype = ''
        if (this.type == '2') d4.cdefaultvalue = this.userInfo.empid
        this.rpParam.push(d4)
        // 审批人责任人
        let d12 = JSON.parse(JSON.stringify(o))
        d12.pname = 'xd_empcode'
        d12.chnname = '审批人'
        d12.controltype = this.type == '2' ? 'combobox' : ''
        d12.mustinput = this.type == '2' ? true : false
        d12.ispoptree = true
        d12.poptreename = 'emptree'
        d12.popupdatefield = 'xd_empid'
        d12.popbackkeyid = 'empid'
        d12.isreadonly = this.type == '1'
        this.rpParam.push(d12)
        // 审批人empid
        let d13 = JSON.parse(JSON.stringify(o))
        d13.pname = 'xd_empid'
        d13.chnname = '审批人empid'
        d13.controltype = ''
        if (this.type == '1') d13.cdefaultvalue = this.userInfo.empid
        this.rpParam.push(d13)
        // 任务级别
        let d5 = JSON.parse(JSON.stringify(o))
        d5.pname = 'tasklevel'
        d5.chnname = '任务级别'
        d5.controltype = 'combobox'
        d5.listsql = '@任务级别'
        this.rpParam.push(d5)
        // 权重系数
        let d6 = JSON.parse(JSON.stringify(o))
        d6.pname = 'taskper'
        d6.chnname = '权重系数'
        d6.listsql = 'float'
        this.rpParam.push(d6)
        // 计划完成时间
        let d7 = JSON.parse(JSON.stringify(o))
        d7.pname = 'planfinishdate'
        d7.chnname = '计划完成时间'
        this.rpParam.push(d7)
        // 里程碑描述
        let d8 = JSON.parse(JSON.stringify(o))
        d8.pname = 'lcbms'
        d8.chnname = '里程碑描述'
        d8.controltype = 'memo'
        this.rpParam.push(d8)
        // 评分标准
        let d9 = JSON.parse(JSON.stringify(o))
        d9.pname = 'pfbz '
        d9.chnname = '评分标准'
        d9.controltype = 'memo'
        this.rpParam.push(d9)
        // 验收人1
        let d10 = JSON.parse(JSON.stringify(o))
        d10.pname = 'ys_empcode1'
        d10.chnname = '验收人1'
        d10.controltype = 'combobox'
        d10.ispoptree = true
        d10.poptreename = 'emptree'
        d10.popupdatefield = 'ys_empid1'
        d10.popbackkeyid = 'empid'
        this.rpParam.push(d10)
        // 验收人1empid
        let d11 = JSON.parse(JSON.stringify(o))
        d11.pname = 'ys_empid1'
        d11.chnname = '验收人1empid'
        d11.controltype = ''
        this.rpParam.push(d11)

        // 验收人1评分占比
        let d19 = JSON.parse(JSON.stringify(o))
        d19.pname = 'ys_per1 '
        d19.chnname = '验收人1评分占比'
        d19.listsql = 'float'
        this.rpParam.push(d19)

        // 验收人2
        let d14 = JSON.parse(JSON.stringify(o))
        d14.pname = 'ys_empcode2'
        d14.chnname = '验收人2'
        d14.controltype = ''
        d14.mustinput = false
        d14.ispoptree = true
        d14.poptreename = 'emptree'
        d14.popupdatefield = 'ys_empid2'
        d14.popbackkeyid = 'empid'
        this.rpParam.push(d14)
        // 验收人2empid
        let d15 = JSON.parse(JSON.stringify(o))
        d15.pname = 'ys_empid2'
        d15.chnname = '验收人2empid'
        d15.controltype = ''
        d15.mustinput = false
        this.rpParam.push(d15)

        // 验收人2评分占比
        let d20 = JSON.parse(JSON.stringify(o))
        d20.pname = 'ys_per2 '
        d20.chnname = '验收人2评分占比'
        d20.listsql = 'float'
        d20.controltype = ''
        d20.mustinput = false
        this.rpParam.push(d20)

        // 验收人3
        let d16 = JSON.parse(JSON.stringify(o))
        d16.pname = 'ys_empcode3'
        d16.chnname = '验收人3'
        d16.controltype = ''
        d16.ispoptree = true
        d16.poptreename = 'emptree'
        d16.popupdatefield = 'ys_empid3'
        d16.popbackkeyid = 'empid'
        d16.mustinput = false
        this.rpParam.push(d16)
        // 验收人3empid
        let d17 = JSON.parse(JSON.stringify(o))
        d17.pname = 'ys_empid3'
        d17.chnname = '验收人3empid'
        d17.controltype = ''
        d17.mustinput = false
        this.rpParam.push(d17)

        // 验收人3评分占比
        let d21 = JSON.parse(JSON.stringify(o))
        d21.pname = 'ys_per3 '
        d21.chnname = '验收人3评分占比'
        d21.listsql = 'float'
        d21.controltype = ''
        d21.mustinput = false
        this.rpParam.push(d21)
      }
      if (this.autoid == '') {
        this.getGsTaskNewDefaultValue()
      } else {
        this.getGsTaskRecordInfo(this.autoid)
      }
    },
    _getKhTimeList() {
      getKhTimeList({
        username: this.userInfo.username,
        flag: 3
      }).then(res => {
        this.khTimeList = res.data
      })
    },
    getGsTaskRecordInfo(autoid) {
      getGsTaskRecordInfo({
        autoid: this.autoid
      }).then(res => {
        if (res.data.length == 1) {
          Object.keys(res.data[0]).map(key => {
            if (res.data[0][key] !== null) {
              this.setValue(key, res.data[0][key].toString())
            }
          })
          if (res.data[0].ys_empid2) {
            this.addys()
            if (res.data[0].ys_empid3) {
              this.addys()
            }
          }
        } else {
          Toast('查询数据失败')
          this.hasroot = false
        }
      })
    },
    // 显示验收人2/3
    addys() {
      this.yznum++
      let i = 17 // 因为字段写死，所以就不做循环了。后期如果自动要动态加在foreach this.rpParam 获取i
      // console.log(this.yznum, this.rpParam)
      if (this.yznum == 3) {
        i = 20
      }
      this.rpParam[i].controltype = 'combobox'
      this.rpParam[i].mustinput = true
      this.rpParam[i + 1].mustinput = true
      this.rpParam[i + 2].controltype = 'edit'
      this.rpParam[i + 2].mustinput = true
    },
    jys() {
      let i = 17
      if (this.yznum == 3) {
        i = 20
      }
      this.rpParam[i].val = ''
      this.rpParam[i].controltype = ''
      this.rpParam[i].mustinput = false
      this.rpParam[i + 1].mustinput = false
      this.rpParam[i + 1].val = ''
      this.rpParam[i + 2].controltype = ''
      this.rpParam[i + 2].mustinput = false
      this.rpParam[i + 2].val = ''
      this.yznum--
    },
    // 获取默认值
    getGsTaskNewDefaultValue() {
      getGsTaskNewDefaultValue({
        username: this.userInfo.username,
        tasksource: this.type == '1' ? '上级通知' : '员工提交'
      }).then(res => {
        res.data.forEach(e => {
          this.setValue(e.fieldname, e.fieldvalue)
        })
      })
    },
    // 打开是判断是否有权限
    checkHasGsTaskPostPri() {
      checkHasGsTaskPostPri({
        empid: this.userInfo.empid,
        flag: this.type == '1' ? 1 : 2
      }).then(res => {
        if (res.data[0][''] == 1) {
          this.hasroot = true
          this.ini()
        } else {
          Toast(`你缺少${this.type == '1' ? '下达' : '申请'}任务权限`)
          this.hasroot = false
        }
      })
    },
    onClickLeft() {
      this.$router.push(this.from)
    },
    dd(m) {
      //日期控件的最大日期/最小日期
      const d = new Date()
      const year = d.getFullYear()
      const month = d.getMonth()
      const day = d.getDate()
      if (m === 'min') {
        return new Date(year - 100, month, day)
      } else {
        return new Date(year + 30, month, day)
      }
    },
    //sql转化成默认值
    SqlValue(b4sql) {
      return new Promise(async (resolve, reject) => {
        let sql = b4sql === null ? '' : b4sql.replace(/(^\s*)|(\s*$)/g, '')
        if (sql.length > 7) {
          if (sql.substring(0, 7).toLowerCase() === 'select ') {
            const params = {
              sql: b4sql,
              username: this.username
            }
            const res = await getSqlValue(params)
            sql = res.topvalue
            resolve(sql)
          }
        } else {
          resolve(sql)
        }
      })
    },
    onConfirm(value, index) {
      if (this.Selectformdata.controltype === 'combobox') {
        this.setValue(this.Selectformdata.fieldname, value)
      } else if (this.Selectformdata.controltype === 'lookupcombobox') {
        this.setValue(this.Selectformdata.fieldname, this.choices[index].keyId)
      }

      this.showPicker = false
    },
    onCancel() {
      this.showPicker = false
    },
    onDtConfirm(value) {
      const v = parseTime(value, '{y}-{m}-{d}')
      this.setValue(this.Selectformdata.fieldname, v)
      this.showDtPicker = false
    },
    // 搜索子节点
    onSearchTree(val) {
      // 清空父节点、子节点和已点击过的父节点
      this.parentTreeList = []
      this.childsTreeList = []
      this.clickParentTree = []
      this.otherTreeList = []
      this.allTreeList.forEach(el => {
        let nodecan = false
        if (this.whichTree == 'station') {
          nodecan = el.issta
        } else if (this.whichTree == 'employee') {
          nodecan = el.isdept == 0 && el.levelid == 16
        }
        // 岗位和人员
        if (this.whichTree == 'station' || this.whichTree == 'employee') {
          if (nodecan && el.keyname.indexOf(val) !== -1) {
            this.childsTreeList.push(el)
          }
        } // 其他（部门）
        else {
          if (el.keyname.indexOf(val) !== -1) {
            // 判断节点有没有下级
            let lowerAry = this.allTreeList.filter(it => {
              return it.parentid == el.keyid
            })
            if (lowerAry.length > 0) {
              el.hasLower = true
            } else {
              el.hasLower = false
            }
            this.otherTreeList.push(el)
          }
        }
      })
    },
    showdatepick(e) {
      this.Selectformdata = e
      this.currentDate = e.formdata ? new Date(e.formdata) : new Date()
      this.showDtPicker = true
    },
    // 设置字段值（不触发数据带出）
    setValue(n, v, tar = 'new') {
      this.rpParamInfo.forEach(it => {
        it.forEach(i => {
          if (i.fieldname === n) {
            let vv = v
            /*数据转化处理
              （bit类型要把 1 转成 true 其他为false）
              日期控件需要转成date格式
              readonly 的情况要转成字符串
            */
            if (i.fielddatatype === 'bit') {
              const truearr = [1, '1', 'true', 'True', 'TRUE', true]
              if (this.new_saveormodify !== 'readonly') {
                vv = truearr.indexOf(v) != -1 ? true : false
              } else {
                vv = truearr.indexOf(v) != -1 ? '是' : '否'
              }
            } else if (
              i.controltype === 'datetime' &&
              vv !== null &&
              this.new_saveormodify !== 'readonly'
            ) {
            } else if (i.controltype === 'memo' && vv !== null) {
              vv = vv.replace(/\\n/g, '\n ')
            }
            i.val = vv
          }
        })
      })
      this.$forceUpdate()
    },
    // 弹出下拉树
    showTree(e) {
      this.multiseltree = e.multiseltree
      this.Selectformdata = e
      this.showTreePop = true
      if (e.poptreename == 'stationtree' || e.poptreename == 'stationidtree') {
        this.whichTree = 'station'
      } else if (e.poptreename.indexOf('emptree') > -1) {
        this.whichTree = 'employee'
      } else {
        this.whichTree = 'other'
      }
      console.log(this.Selectformdata, 'this.Selectformdata ')
      this.getCommTree(e.multiseltree)
      // this.$refs.addtree.show = true //开发中
    },
    // 获取通用树
    getCommTree(multiseltree) {
      const editstate = 1
      getCommTreeData({
        moduleno: parseInt(this.moduleno),
        treename: this.Selectformdata.poptreename, // 树名
        username: this.userInfo.username + '_wx_gs',
        fieldname: this.Selectformdata.fieldname,
        editstate: editstate,
        formstr: ''
      }).then(res => {
        // 如果是多选树，则返回记录添加是否多选，是否选中属性
        if (multiseltree) {
          res.data.forEach(element => {
            element.multiple = multiseltree
            element.checked = false
          })
        }
        this.allTreeList = res.data
        // 树结构中最小层级(最上层)
        let mini = Math.min.apply(
          Math,
          res.data.map(item => {
            return item.levelid
          })
        )
        if (this.whichTree == 'station' || this.whichTree == 'employee') {
          // 岗位和员工树
          // 初始时的父节点
          this.parentTreeList = res.data.filter(item => {
            return item.levelid == mini
          })
          // 判断父节点有没有下级
          if (this.parentTreeList.length > 0) {
            this.parentTreeList.forEach(el => {
              el.hasLower = false
              for (let i = 0; i < this.allTreeList.length; i++) {
                const it = this.allTreeList[i]
                if (el.keyid == it.parentid) {
                  el.hasLower = true
                  break
                }
              }
            })
          }
          // 初始时的子节点
          this.allTreeList.forEach(el => {
            if (this.parentTreeList.length == 1) {
              // 最大父节点只有一个的时候
              let nodecan = false
              if (this.whichTree == 'station') {
                nodecan = el.issta
              } else if (this.whichTree == 'employee') {
                nodecan = el.isdept == 0 && el.levelid == 16
              }
              if (nodecan && el.parentid == this.parentTreeList[0].keyid) {
                this.childsTreeList.push(el)
              }
            } else {
              // 最大父节点有多个的时候
              this.childsTreeList = []
            }
          })
        } else {
          // 其他树(暂时部门树)
          // 初始时的节点
          this.otherTreeList = res.data.filter(item => {
            return item.levelid == mini
          })
          // 判断初始节点有没有下级
          if (this.otherTreeList.length > 0) {
            this.otherTreeList.forEach(el => {
              el.hasLower = false
              for (let i = 0; i < this.allTreeList.length; i++) {
                const it = this.allTreeList[i]
                if (el.keyid == it.parentid) {
                  el.hasLower = true
                  break
                }
              }
            })
          }
        }
        console.log(this.allTreeList, 'this.allTreeList')
      })
    },
    // 搜索子节点
    onSearchTree(val) {
      // 清空父节点、子节点和已点击过的父节点
      this.parentTreeList = []
      this.childsTreeList = []
      this.clickParentTree = []
      this.otherTreeList = []
      this.allTreeList.forEach(el => {
        let nodecan = false
        if (this.whichTree == 'station') {
          nodecan = el.issta
        } else if (this.whichTree == 'employee') {
          nodecan = el.isdept == 0 && el.levelid == 16
        }
        // 岗位和人员
        if (this.whichTree == 'station' || this.whichTree == 'employee') {
          if (nodecan && el.keyname.indexOf(val) !== -1) {
            this.childsTreeList.push(el)
          }
        } // 其他（部门）
        else {
          if (el.keyname.indexOf(val) !== -1) {
            // 判断节点有没有下级
            let lowerAry = this.allTreeList.filter(it => {
              return it.parentid == el.keyid
            })
            if (lowerAry.length > 0) {
              el.hasLower = true
            } else {
              el.hasLower = false
            }
            this.otherTreeList.push(el)
          }
        }
      })
    },
    // 回到某个树节点
    backTo(val) {
      if (val === true) {
        // 点击的开始,回到最初始状态
        this.parentTreeList = []
        this.childsTreeList = []
        this.clickParentTree = []
        this.otherTreeList = []
        this.searchTreeVal = ''
        this.allTreeList = []
        this.getCommTree(this.multiseltree)
      } else {
        // 否则先在clickParentTree去掉该父节点及节点后所有节点
        let ary = [] // 记录该节点及后的所有节点
        for (let i = 0; i < this.clickParentTree.length; i++) {
          const ele = this.clickParentTree[i]
          if (val.keyid == ele.keyid) {
            // 如果是数组最后一个则不可以点击，数据不变
            if (i == this.clickParentTree.length - 1) {
              return false
            } else {
              ary = this.clickParentTree.filter((item, index) => {
                return index < i
              })
            }
          }
        }
        this.clickParentTree = ary
        // 去掉之后再区分（岗位和员工）和其他树
        if (this.whichTree == 'station' || this.whichTree == 'employee') {
          // 岗位和员工树
          // 再获取该父节点下的子节点
          this.checkParent(val)
        } else {
          // 其他树
          this.hasLower(val)
        }
      }
    },
    // 其他树点击下级
    hasLower(val) {
      // 储存点击过的有下级的父节点
      if (val.hasLower) {
        this.clickParentTree.push(val)
      }
      // 获取此节点下所有子节点
      let array = this.allTreeList.filter(item => {
        return item.parentid == val.keyid
      })
      if (array.length > 0) {
        this.otherTreeList = []
        array.forEach(item => {
          // 判断节点有没有下级
          let lowerAry = this.allTreeList.filter(it => {
            return it.parentid == item.keyid
          })
          if (lowerAry.length > 0) {
            item.hasLower = true
          } else {
            item.hasLower = false
          }
          // 父节点
          this.otherTreeList.push(item)
        })
      }
    },
    // 点击树的父节点(针对岗位和员工树)
    checkParent(val) {
      // 储存点击过的有下级的父节点
      if (val.hasLower) {
        this.clickParentTree.push(val)
      }
      // 获取此父节点下所有节点
      let array = this.allTreeList.filter(item => {
        return item.parentid == val.keyid
      })
      // 遍历所有节点并分出父节点和子节点
      if (array.length > 0) {
        this.parentTreeList = []
        this.childsTreeList = []
        array.forEach(item => {
          let nodecan = false
          if (this.whichTree == 'station') {
            nodecan = item.issta
          } else if (this.whichTree == 'employee') {
            nodecan = item.isdept == 0 && item.levelid == 16
          }
          if (nodecan) {
            // 子节点
            this.childsTreeList.push(item)
          } else {
            // 判断父节点有没有下级
            let lowerAry = this.allTreeList.filter(it => {
              return it.parentid == item.keyid
            })
            if (lowerAry.length > 0) {
              item.hasLower = true
            } else {
              item.hasLower = false
            }
            // 父节点
            this.parentTreeList.push(item)
          }
        })
      }
    },
    // 选择父节点复选框
    tickParent(item) {
      // 判断父节点有没有下级
      let lowerAry = this.allTreeList.filter(it => {
        return it.parentid == item.keyid
      })
      if (lowerAry.length > 0) {
        if (item.checked == true) {
          lowerAry.forEach(ele => {
            this.allTreeList.map(it => {
              if (it.keyid == ele.keyid) {
                it.checked = true
              }
            })
            this.tickParent(ele)
          })
        } else {
          lowerAry.forEach(ele => {
            this.allTreeList.map(it => {
              if (it.keyid == ele.keyid) {
                it.checked = false
              }
            })
            this.tickParent(ele)
          })
        }
      }
    },
    // 多选树清除复选框选中
    cleanChecked() {
      this.allTreeList.forEach(item => {
        item.checked = false
      })
    },
    // 多选树确认复选框选中
    comfirmChecked() {
      let array = this.allTreeList.filter(ele => {
        return ele.checked == true
      })
      let trueList = []
      if (array.length > 0) {
        if (this.whichTree == 'station') {
          for (let i = 0; i < array.length; i++) {
            const it = array[i]
            if (it.issta == true) {
              trueList.push(it)
            }
          }
        } else if (this.whichTree == 'employee') {
          for (let l = 0; l < array.length; l++) {
            const item = array[l]
            if (item.isdept == 0 && item.levelid == 16) {
              trueList.push(item)
            }
          }
        } else {
          // 其他树
          trueList = array
        }
      }
      this.checkChild(trueList)
      console.log(trueList, 'trueListtrueList')
    },
    // 点击树的子节点(暂时单选树)(针对岗位和员工树)
    checkChild(it) {
      console.log(it, 'it')

      let checked = []
      this.hasEXfieldlist = []
      if (this.Selectformdata.multiseltree) {
        checked = it
        //多选树
        const namelist = checked.map(it =>
          it.keyname.indexOf(' ') != -1 ? it.keyname.split(' ')[0] : it.keyname
        )
        const names = namelist.join(',')
        this.setValue(this.Selectformdata.fieldname, String(names))
        // 下拉字段带出过程
        if (this.Selectformdata.isexitvalue) {
          this.valuechage(names, this.Selectformdata) //判断数据带出
        }
      } else {
        checked = [it]
        //单选树
        if (this.whichTree == 'station' || this.whichTree == 'employee') {
          this.setValue(
            this.Selectformdata.fieldname,
            String(
              checked[0].keyname.indexOf(' ') != -1
                ? checked[0].keyname.split(' ')[1]
                : checked[0].keyname
            )
          )
        } else {
          this.setValue(
            this.Selectformdata.fieldname,
            String(
              checked[0].keyname.indexOf(' ') != -1
                ? checked[0].keyname.split(' ')[1]
                : checked[0].keyname
            )
          )
        }
        this.setValue(
          this.Selectformdata.popupdatefield,
          String(checked[0][this.Selectformdata.popbackkeyid])
        )
        // 下拉字段带出过程
        if (this.Selectformdata.isexitvalue) {
          this.valuechage(
            String(
              checked[0].keyname.indexOf(' ') != -1
                ? checked[0].keyname.split(' ')[0]
                : checked[0].keyname
            ),
            this.Selectformdata
          ) //判断数据带出
        }
        // //转化字段带出过程
        // this.form.loopData.forEach(item => {
        //   item.forEach(it => {
        //     if (
        //       it.isexitvalue &&
        //       it.fieldname === this.Selectformdata.popupdatefield
        //     ) {
        //       this.valuechage(checked[0][this.Selectformdata.popbackkeyid], it) //判断数据带出
        //     }
        //   })
        // })
      }
      // 最终关闭弹窗
      this.showTreePop = false
    },
    // 获取下拉选项（combobox，lookupcombobox）
    comboboxNoispoptreefocus(data, controltype, e) {
      this.Selectformdata = e
      if (data == 'khTimeList') {
        let tempdata = []
        //普通下拉框只取第一个属性值，并且明明为dicvalue
        this.khTimeList.forEach(item => {
          // tempdata.push({ dicvalue: item[Object.keys(item)[0]] })
          tempdata.push(item.khtimename)
        })
        this.choices = tempdata
        this.showPicker = true
      } else {
        GetFilterDropDownList({
          moduleno: this.moduleno,
          username: this.userInfo.username,
          listsql: data,
          formstr: ''
        }).then(res => {
          if (controltype === 'filse') {
            // 加载附件名
            let tempdata = []
            //普通下拉框只取第一个属性值，并且明明为dicvalue
            res.data.forEach(item => {
              tempdata.push({
                url: null,
                name: item[Object.keys(item)[0]],
                file: null,
                type: 'no'
              })
            })
            this.fileList = tempdata
          } else if (
            controltype === 'combobox' ||
            controltype === 'radiogroup'
          ) {
            let tempdata = []
            //普通下拉框只取第一个属性值，并且明明为dicvalue
            res.data.forEach(item => {
              // tempdata.push({ dicvalue: item[Object.keys(item)[0]] })
              tempdata.push(item[Object.keys(item)[0]])
            })
            this.choices = tempdata
            this.showPicker = true
            // this.showPickerData(tempdata, e) //打开下拉框
          } else if (controltype === 'checkgroup') {
            let tempdata = []
            res.data.forEach(item => {
              // tempdata.push({ dicvalue: item[Object.keys(item)[0]] })
              tempdata.push({
                keyname: item[Object.keys(item)[0]],
                parentid: 0,
                hasLower: false,
                checked: false
              })
            })
            this.otherTreeList = tempdata
            this.allTreeList = tempdata
            this.multiseltree = true
            this.showTreePop = true
          } else if (controltype === 'lookupcombobox') {
            let tempdata2 = { head: [{ name: [], width: [] }], data: [] }
            if (res.data.length > 0) {
              Object.keys(res.data[0]).forEach(item => {
                tempdata2.head[0].name.push(item)
                tempdata2.head[0].width.push(item.toString().length)
              })
            }
            //表格下拉框需要新增表头数据
            for (let i = 0; i < res.data.length; i++) {
              var tp = { keyId: '', text: '' }
              for (let j = 0; j < tempdata2.head[0].name.length; j++) {
                if (res.data[i][tempdata2.head[0].name[j]] != null) {
                  if (j === 0) {
                    tp.keyId = res.data[i][tempdata2.head[0].name[j]].toString()
                    tp.text = tp.keyId
                  } else {
                    tp.text += '/' + res.data[i][tempdata2.head[0].name[j]]
                  }
                }
              }
              tempdata2.data.push(tp)
            }
            this.choices = tempdata2.data
            this.showPicker = true
          }
        })
      }
    },
    // 下拉框输入自动添加选项
    addoption(list, e) {
      let v = e.target.value
      if (list.comdropstyle === 'lsEditList' && v !== '') {
        //允许手动输入
        if (list.controltype === 'combobox' && !list.ispoptree) {
          //普通输入框
          if (this.choices === '') this.choices = []
          const op = { dicvalue: v }
          if (this.choices.indexOf(op) === -1) this.choices.push(op)
        } else if (list.controltype === 'lookupcombobox' && !list.ispoptree) {
          // console.log(this.choices,'lookupcombobox');
          if (this.choices === '') this.choices = {}
          if (this.choices.head.length === 0) {
            //无表头
            this.choices.head = [{ name: ['dicvalue'], width: [8] }]
          }
          let has = false
          this.choices.data.forEach(choice => {
            if (choice[0] === v) {
              has = true
              return
            }
          })
          if (!has) {
            let tp = []
            this.choices.head[0].name.forEach((choice, i) => {
              if (i === 0) {
                tp.push(v)
              } else {
                tp.push('')
              }
            })
            this.choices.data.push(tp)
          }
        }
        list.val = v
      }
    },
    // 提交
    ok() {
      let paramvaluelist = { remark: '', username: this.userInfo.username },
        a = [
          'empcode',
          'xd_empcode',
          'ys_empcode1',
          'ys_empcode2',
          'ys_empcode3'
        ],
        b = [
          'ys_empid1',
          'ys_empid2',
          'ys_empid3',
          'ys_per1',
          'ys_per2',
          'ys_per3',
          'taskper',
          'empid',
          'xd_empid'
        ],
        cansave = true
      if (this.autoid) {
        paramvaluelist.autoid = this.autoid
      }
      this.rpParamInfo.forEach(i => {
        i.forEach(item => {
          let v = item.val
            ? item.val
            : item.controltype === 'datetime'
            ? 'null'
            : ''
          if (item.controltype === 'checkgroup') {
            v = item.val.length > 0 ? item.val.join(',') : ''
          }
          if (item.mustinput && !v) {
            Toast.fail(item.chnname + ' 必填！')
            cansave = false
            return
          }
          if (a.indexOf(item.pname) < 0) {
            if (b.indexOf(item.pname) < 0) {
              paramvaluelist[item.pname] = v
            } else {
              if (v == '') {
                paramvaluelist[item.pname] = null
              } else {
                paramvaluelist[item.pname] = parseFloat(v)
              }
            }
          }
        })
      })
      let zys = paramvaluelist.ys_per1
      if (paramvaluelist.ys_per2) zys = add(zys, paramvaluelist.ys_per2)
      if (paramvaluelist.ys_per3) zys = add(zys, paramvaluelist.ys_per3)
      if (zys != 100) {
        Toast.fail('总验收权重不等于100！')
        cansave = false
        return
      }
      // 必填判断
      if (cansave) {
        this.beforeAddGsTaskListInfo(paramvaluelist)
      }
    },
    // 保存前
    beforeAddGsTaskListInfo(paramvaluelist) {
      beforeAddGsTaskListInfo(paramvaluelist).then(res => {
        if (res.iserror === '0') {
          if (res.data[0].isinfo) {
            if (res.data[0].casave) {
              // 询问框
              this.showBTYDia
              this.msg = res.data[0].info
              this.paramvaluelist = paramvaluelist
            } else {
              Toast(res.data[0].info)
            }
          } else {
            if (this.autoid) {
              this.saveGsTaskList(paramvaluelist)
            } else {
              this.addGsTaskList(paramvaluelist)
            }
          }
        } else {
          Toast(res.errormsg)
        }
      })
    },
    closeBTYDia(action, done) {
      if (action == 'confirm') {
        this.addGsTaskList(this.paramvaluelist)
      } else {
        done()
      }
    },
    addGsTaskList(paramvaluelist) {
      addGsTaskList(paramvaluelist).then(res => {
        if (res.iserror === '0') {
          if (res.data[0].info) {
            Toast(res.data[0].info)
          } else {
            Toast.success(this.$t('module.createSuc'))
            this.onClickLeft()
          }
        }
      })
    },
    saveGsTaskList(paramvaluelist) {
      saveGsTaskList(paramvaluelist).then(res => {
        if (res.iserror === '0') {
          if (res.data[0].info) {
            Toast(res.data[0].info)
          } else {
            // Toast.success(this.$t('module.createSuc'))
            // this.onClickLeft()
            if (this.type == '1') {
              //上级
              this.rePostGsTask()
            } else {
              // 员工
              this.rePostGsTaskForEmp()
            }
          }
        }
      })
    },
    //上级重新提交任务接口
    rePostGsTask() {
      rePostGsTask({
        autoid: this.autoid,
        username: this.userInfo.username
      }).then(res => {
        if (res.iserror === '0') {
          if (res.data[0].info) {
            Toast(res.data[0].info)
          } else {
            Toast.success(this.$t('module.createSuc'))
            this.onClickLeft()
          }
        }
      })
    },
    //员工重新提交任务接口
    rePostGsTaskForEmp() {
      rePostGsTaskForEmp({
        autoid: this.autoid,
        username: this.userInfo.username
      }).then(res => {
        if (res.iserror === '0') {
          if (res.data[0].info) {
            Toast(res.data[0].info)
          } else {
            Toast.success(this.$t('module.createSuc'))
            this.onClickLeft()
          }
        }
      })
    },
    zf() {
      this.showDia = true
    },
    // 弹窗关闭回调
    closeDia(action, done) {
      if (action == 'confirm') {
        if (this.checkmemo) {
          let data = {
            autoid: this.autoid,
            checkmemo: this.checkmemo,
            username: this.userInfo.username
          }
          if (this.type == '1') {
            //上级
            cancelNotCheckGsTask(data).then(res => {
              if (res.iserror === '0') {
                if (res.data[0].info) {
                  Toast(res.data[0].info)
                } else {
                  Toast.success(this.$t('module.submitScu'))
                  this.onClickLeft()
                }
              }
            })
          } else {
            // 员工
            cancelNotSureGsTask(data).then(res => {
              if (res.iserror === '0') {
                if (res.data[0].info) {
                  Toast(res.data[0].info)
                } else {
                  Toast.success(this.$t('module.submitScu'))
                  this.onClickLeft()
                }
              }
            })
          }

          done()
        } else {
          Toast.fail(this.$t('jxApprovalTagApply.atTxt8'))
          done(false)
        }
      } else {
        done()
      }
    }
  }
}
</script>
<style lang="less" scoped>
.addFrom {
  padding-top: 30px;
  padding-bottom: 150px;
  width: 100vw;
  // height: calc(100vh - 150px);
  .van-nav-bar {
    background: #2b8df0;
    height: 92px;
    /deep/ .van-nav-bar__left {
      .van-icon {
        color: #fff;
      }
    }
    /deep/.van-nav-bar__title {
      color: #fff;
      font-size: 36px;
      font-family: Source Han Sans CN;
    }
    ::v-deep .van-nav-bar__right {
      .van-nav-bar__text {
        color: #fff;
        font-family: Source Han Sans CN;
      }
    }
  }
  .van-form {
    .right_content {
      padding: 0 0;
      overflow-y: scroll;
      .right_titlepar {
        // border-bottom: 1px solid #ccc;
        border-bottom: 1px solid #bababc;
        // #bababc
        padding: 0 4vw;
        .right_title {
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-size: 5vw;
          min-height: 11.46667vw;
          // line-height: 11.46667vw;
          .lump {
            height: 3vw;
            width: 1vw;
            // background: $active-color;
            display: inline-block;
            margin-right: 1vw;
          }
          .titlebtn {
            background: none;
            border: none;
            font-size: 20px;
          }
          .titlebtn:hover {
            background: none;
            border: none;
            // color: $active-color;
          }
        }
      }
      .sheet {
        margin-top: 10px;
        width: 100%;
        display: flex;
        flex-direction: column;
        .elwrapper:after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 2.66vw;
          right: 2.66vw;
          height: 1px;
          border-top: 0.13333vw solid #eee;
        }
        .elwrapper {
          display: flex;
          align-items: center;
          flex: none;
          width: 100%;
          min-height: 10vw;
          position: relative;
          .van-field {
            /deep/ .van-cell__title {
              border-right: 0.13333vw solid #eee;
              display: flex;
              align-items: center;
            }
          }
          .readonly {
            /deep/ [readonly='readonly'] {
              background: #eaeaea;
            }
          }
        }
      }
    }
  }

  .tree_popup {
    z-index: 6666 !important;
    .tree_title {
      height: 90px;
      text-align: center;
      span {
        font-size: 36px;
        font-weight: bold;
      }
    }
    .tree_search {
      height: 100px;
    }
    .tree_head {
      height: 90px;
      white-space: nowrap;
      overflow-x: auto;
      display: flex;
      align-items: center;
      span {
        font-size: 32px;
      }
      .theLast {
        color: #969799;
        margin-right: 10px;
      }
      .noLast {
        color: #1491f7;
      }
      .head_start {
        color: #1491f7;
        padding: 0 10px 0 20px;
      }
      .division {
        color: #969799;
        padding: 0 16px;
      }
    }
    .van-divider::before {
      border-width: 20px 0 0;
    }

    .tree_body {
      height: calc(100% - 5.7rem);
      overflow: auto;
      .parents_cell {
        .van-checkbox {
          padding-right: 10px;
        }
        .van-cell__title {
          flex: 1 1 83%;
          display: flex;
          align-items: center;
        }
        .van-cell__value {
          flex: 1 1 17%;
          div {
            display: flex;
            align-items: center;
            justify-content: end;
            border-left: 1px solid #969799;
          }
          .haveClo {
            color: #1491f7;
          }
          .noClo {
            color: #969799;
          }
          .van-icon {
            padding: 0 10px;
          }
        }
      }
      .childs_cell,
      .other_cell {
        .van-cell__title {
          display: flex;
          align-items: center;
          .van-button {
            border-radius: 12px;
            font-size: 28px;
          }
          .check_name {
            padding-left: 10px;
          }
        }
      }
    }
    .tree_foot {
      position: absolute;
      bottom: 0;
      width: 100%;
      background: #fff;
      display: flex;
      justify-content: space-evenly;
      .van-button {
        width: 30vw;
        margin-bottom: 10px;
      }
    }

    /deep/.van-popup__close-icon--top-left {
      top: 25px !important;
      left: 10px !important;
    }
  }
  .foot {
    width: calc(100vw - 30px);
    margin: 10px 15px;
  }
  .footer_btn {
    width: 100%;
    padding: 0.26667rem 0;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 100;
    display: flex;
    background-color: #fff;
    .van-button {
      width: 60%;
      margin: 0 2%;
      border-radius: 9px;
      // width: 3.86667rem;
      .van-button__text {
        font-size: 0.48rem;
        font-family: Source Han Sans CN;
        font-weight: 500;
      }
    }
    .backButton {
      width: 30%;
      height: 90px;
      text-align: center;
      line-height: 90px;
      background: #999;
      color: #fff;
      font-size: 36px;
      border-radius: 12px;
      border-color: #999;
    }
  }
}
</style>
